import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import MiniDrawer from "../components/MiniDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBedPulse, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddNewProducts from "../Modals/AddNewProducts";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import { Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function ProductList({ base_url,  msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");

  const [productList, setProductList] = useState([]);
  const [productName, setProductName] = useState("");
  const [selectCategoryId, setSelectCategoryId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [productId, setProductId] = useState("");
  const [quantityunitId, setQuantityUnitId] = useState("");
  const [quantityTitle, setQuanityTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpenAddProduct = () => setOpen(true);
  const handleCloseAddProduct = () => setOpen(false);

  
  // ******************get product master table list******************
  const get_product_list = async () => {
    setIsLoading(true);
    try {
      let res = await fetch(`${base_url}/Product.svc/get_ALL_RECORDS/ALL`);
      let data = await res.json();
      setProductList(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      msg_popUp("error", "Something went wrong!")
    }
  };

  // ******************get category dropdown list******************
  const get_category_list = async () => {
    try {
      let res = await fetch(
        `${base_url}/Category.svc/get_ALL_RECORDS_DROPDOWN/ALL`
      );
      let data = await res.json();
      setCategoryList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // ******************get units dropdown list******************
  const get_unit_list = async () => {
    try {
      let res = await fetch(
        `${base_url}/CommonComboBox.svc/get_ALL_UNITORQUANTITYRECORDS_DROPDOWN`
      );
      let data = await res.json();
      setUnitList(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // ******************Delete product******************
  const handleDeleteProduct = async (prod_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await fetch(
            `${base_url}/Product.svc/delete_RECORD/${prod_id}`
          );
          let data = await res.json();
          if(data.CODE == "200") {
            handleCloseAddProduct();
            Swal.fire({
              title: "Deleted!",
              text: data?.MESSAGE,
              icon: "success",
            });
            get_product_list();
            setProductId("");
            setCategoryId("");
            setQuantityUnitId("");
            setProductName("");
            setQuanityTitle("");
          }
          else {
            handleCloseAddProduct();
            setProductId("");
            setCategoryId("");
            setQuantityUnitId("");
            setProductName("");
            setQuanityTitle("");
            msg_popUp("error", data?.MESSAGE)
          }
        } catch (error) {
          console.log(error);
        }
      }
      else {
        setProductId("");
        setCategoryId("");
        setQuantityUnitId("");
        setProductName("");
        setQuanityTitle("");
      }
    });
  };

  // ******************edit product******************
  const handleEditProduct = async () => {
    try {
      let res = await fetch(`${base_url}/Product.svc/update_Record`, {
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        body: JSON.stringify({
          PRODUCT_NAME: productName,
          CATEGORY_ID: categoryId,
          // QUANTITY_ID : "ACCFE9E1-97F3-48A4-8F1B-025F954BB22C",
          QUANTITY_ID: quantityunitId,
          USER_ID: user_id,
          PRODUCT_ID: productId,
        }),
      });
      let data = await res?.json();
      if (data?.response?.CODE == "200") {
        handleCloseAddProduct();
        setProductId("");
        setCategoryId("");
        setQuantityUnitId("");
        setProductName("");
        setQuanityTitle("");
        get_product_list();
        msg_popUp("success", data?.response?.MESSAGE)
      } else {
        msg_popUp("error", data?.response?.MESSAGE)
      }
    } catch (error) {
      msg_popUp("error", "Something went wrong!")
      console.log(error);
    }
  };

  // ******************add new product and edit product******************
  const handleAddProduct = async () => {
    if (isEdit) {
      handleEditProduct();
    } else {
      try {
        let res = await fetch(`${base_url}/Product.svc/insert_Record`, {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            PRODUCT_NAME: productName,
            CATEGORY_ID: categoryId,
            // QUANTITY_ID : "ACCFE9E1-97F3-48A4-8F1B-025F954BB22C",
            QUANTITY_ID: quantityunitId,
            USER_ID: user_id,
          }),
        });
        let data = await res.json();
        if (data?.response?.CODE == "200") {
          handleCloseAddProduct();
          setProductId("");
          setCategoryId("");
          setQuantityUnitId("");
          setProductName("");
          setQuanityTitle("");
          get_product_list();
          msg_popUp("success", data?.response?.MESSAGE)
        } else {
          msg_popUp("error", data?.response?.MESSAGE)
        }
      } catch (error) {
        msg_popUp("error", "Something Went Wrong!")
        console.log(error);
      }
    }
  };

  useEffect(() => {
    get_product_list();
    get_category_list();
    get_unit_list();
  }, []);

  const columns = [
    {
      name: "PRODUCT_ID",
      label: "Product ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "CATEGORY_ID",
      label: "Category ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "QUANTITY_ID",
      label: "Quantity ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    // {
    //   name: "",
    //   label: "Sr. No.",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: false,
    //     customBodyRender: (row, data) => {
    //       return (
    //         <p
    //           style={{ marginBottom: "0px", padding: "5px", width:"7vw", display:"flex", justifyContent:"center" }}
    //           // style={{width:"3vw", textAlign:"center", marginBottom:"0px", padding:"7px", fontSize:"14px"}}
    //         >
    //           {data.rowIndex + 1}
    //         </p>
    //       );
    //     },
    //   },
    // },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div>
              <Tooltip title="Edit" placement="top">
                <EditIcon
                  onClick={() => onClickEdit(data)}
                  style={{
                    color: "#007cc3",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete" placement="top">
                <DeleteForeverIcon
                  onClick={() => onClickDelete(data)}
                  style={{ color: "red", cursor: "pointer", fontSize: "20px" }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "PRODUCT_NAME",
      label: "Product Name",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: ()=>{
          return (
            <div
                style={{
                  textAlign:"left", color:"white", marginTop:"3px", marginLeft:"10px"}}>
                  Product Name
              </div>)
        },
        customBodyRender: (row, data) => {
          return (
            <p
            style={{display:"flex", justifyContent:"flex-start", width:"50vw", margin:"5px 15px 5px 15px",}}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "QUANTITY_TITLE",
      label: "Quantity",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
            style={{display:"flex", justifyContent:"center", width:"10vw"}}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "CATEGORY_NAME",
      label: "Category",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
            // style={{ textAlign:"center", marginBottom:"0px", padding:"7px", fontSize:"14px"}}
            style={{display:"flex", justifyContent:"center", width:"19vw"}}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "ADDED_ON",
      label: "Added On",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
              style={{
                 paddingRight:"10px",
              }}
            >
              {row}
            </p>
          );
        },
      },
    },
    
  ];

  const options = {
    filterType: "checkbox",
  };

  const onClickEdit = (data) => {
    setIsEdit(true);
    setProductId(data?.rowData[0]);
    setCategoryId(data?.rowData[1]);
    setQuantityUnitId(data?.rowData[2]);
    // setQuantityUnitId("ACCFE9E1-97F3-48A4-8F1B-025F954BB22C")
    setProductName(data?.rowData[4]);
    setQuanityTitle(data?.rowData[5]);
    handleOpenAddProduct();
  };

  const onClickDelete = (data) => {
    handleDeleteProduct(data.rowData[0]);
  };

  return (
    <>
      <MiniDrawer header_name="Products Master" />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div>
            <MUIDataTable
              title={
                <h4
                  className="tableName"
                  style={{ textAlign: "left", marginBottom: "0px" }}
                >
                  {" "}
                  Product List
                </h4>
              }
              data={productList}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: true,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "63vh",
                rowsPerPageOptions: [10, 25, 50, 100],
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <button
                      className="btn btn-outline-primary btn-sm addButton"
                      onClick={handleOpenAddProduct}
                    >
                      Add Products &nbsp;
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: (
                      <p style={{ fontSize: "16px", margin: "5px 0px" }}>
                        Data Not Available
                      </p>
                    ),
                  },
                },
              }}
            />
          </div>
        </div>
      )}

      <AddNewProducts
        base_url={base_url}
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpenAddProduct}
        handleClose={handleCloseAddProduct}
        productName={productName}
        setProductName={setProductName}
        quantity={quantity}
        setQuantity={setQuantity}
        selectCategoryId={selectCategoryId}
        setSelectCategoryId={setSelectCategoryId}
        categoryList={categoryList}
        unitList={unitList}
        selectedUnit={selectedUnit}
        setSelectedUnit={setSelectedUnit}
        handleAddProduct={handleAddProduct}
        productId={productId}
        setProductId={setProductId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        quantityunitId={quantityunitId}
        setQuantityUnitId={setQuantityUnitId}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        quantityTitle={quantityTitle}
        msg_popUp={msg_popUp}
      />
    </>
  );
}

export default ProductList;
