import React, { useEffect, useState } from "react";
import MiniDrawer from "../components/MiniDrawer";
import MUIDataTable from "mui-datatables";
import AddNewCategory from "../Modals/AddNewCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmationModal from "../Modals/ConfirmationModal";

function CategoryPage({ base_url, msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");

  const [open, setOpen] = useState(false);
  const handleOpenAddCategory = () => setOpen(true);
  const handleCloseAddCategory = () => setOpen(false);
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryList, setcategoryList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [priorityId, setPriorityId] = useState("");

  const [selectedPriorityId, setSelectedPriorityId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setisEdit] = useState(false);

  // const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  // const handleConfirmOpen = () => setIsConfirmOpen(true);
  // const handleConfirmClose = () => setIsConfirmOpen(false);

  // ************getcategory master table list
  const get_category_list = async () => {
    setIsLoading(true);
    try {
      let res = await fetch(`${base_url}/Category.svc/get_ALL_RECORDS/ALL`);
      let data = await res.json();
      setcategoryList(data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      msg_popUp("error", "Something went wrong!")
    }
  };

  // *******************on delete category
  const handleDeleteCategory = async (cat_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await fetch(
            `${base_url}/Category.svc/delete_RECORD/${cat_id}`
          );
          let data = await res.json();
          if (data.CODE == "200") {
            handleCloseAddCategory();
            Swal.fire({
              title: "Deleted!",
              text: data?.MESSAGE,
              icon: "success",
            });
            get_category_list();
            setCategory("");
            setCategoryId("");
          } else {
            handleCloseAddCategory();
            setCategory("");
            setCategoryId("");
            msg_popUp("error", data?.MESSAGE)
          }
          // window.location.reload();
        } catch (error) {
          console.log(error);
          msg_popUp("error", "Something went wrong!")
        }
      } else {
        setCategory("");
        setCategoryId("");
      }
    });
  };

  // ***********on submit of edit category
  const handleEditCategory = async () => {
    try {
      let res = await fetch(`${base_url}/Category.svc/update_Record`, {
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        body: JSON.stringify({
          CATEGORY_NAME: category,
          CATEGORY_ID: categoryId,
          PRIORITY_ID: null,
          // PRIORITY_ID: JSON.stringify(selectedPriorityId),
          USER_ID: user_id,
        }),
      });
      let data = await res.json();
      if (data.response.CODE == "200") {
        setCategory("");
        setCategoryId("");
        setPriorityId("");
        setSelectedPriorityId(null);
        setisEdit(false);
        // window.location.reload();
        get_category_list();
        handleCloseAddCategory();
        msg_popUp("success", data?.response?.MESSAGE)
      } else {
        msg_popUp("error", data?.response?.MESSAGE)
      }
    } catch (error) {
      console.log(error);
      msg_popUp("error", "Something went wrong!")
    }
  };

  // ***********on submit of Add new category
  const handleAddCategory = async () => {
    if (isEdit) {
      handleEditCategory();
    } else {
      try {
        let res = await fetch(`${base_url}/Category.svc/insert_Record`, {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            CATEGORY_NAME: category,
            USER_ID: user_id,
            PRIORITY_ID: null,
            // PRIORITY_ID: JSON.stringify(selectedPriorityId),
          }),
        });
        let data = await res.json();
        if (data.response.CODE == "200") {
          setCategory("");
          setPriorityId("");
          setCategoryId("");
          setSelectedPriorityId(null);
          // window.location.reload();
          get_category_list();
          handleCloseAddCategory();
          msg_popUp("success", data?.response?.MESSAGE)
        } else {
          msg_popUp("error", data?.response?.MESSAGE)
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    get_category_list();
    // get_priority_list();
  }, []);

  const columns = [
    {
      name: "CATEGORY_ID",
      label: "Category ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PRIORITY_ID",
      label: "Priority ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "VEN_CAT_COUNT",
      label: "VEN CAT COUNT",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PROD_CAT_COUNT",
      label: "PROD CAT COUNT",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PR_CAT_COUNT",
      label: "PR CAT COUNT",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    // {
    //   name: "sr_no",
    //   label: "Sr. No.",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     // customHeadRender: ()=>{
    //     //   return (
    //     //     <div
    //     //         style={{
    //     //           padding:"5px",textAlign:"center", color:"white", marginTop:"1px"}}>
    //     //           <p >Sr. No.</p>
    //     //       </div>)
    //     // },
    //     customBodyRender: (row, data) => {
    //       return (
    //         <p
    //           style={{
    //             width: "6vw",
    //             textAlign: "center",
    //             marginBottom: "0px",
    //             padding: "5px",
    //             // fontSize:"14px"
    //           }}
    //         >
    //           {data.rowIndex + 1}
    //         </p>
    //       );
    //     },
    //   },
    // },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <div>
              <Tooltip title="Edit" placement="top">
                <EditIcon
                  onClick={() => onClickEdit(data)}
                  style={{
                    color: "#007cc3",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete" placement="top">
                <DeleteForeverIcon
                  onClick={() => onClickDelete(data)}
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "20px",
                    display:
                      data?.rowData[2] > 0 ||
                      data?.rowData[3] > 0 ||
                      data?.rowData[4] > 0 ? 
                      "none" : "inline",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "CATEGORY_NAME",
      label: "Category Name",
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: ()=>{
          return (
            <div
                style={{
                  textAlign:"left", color:"white", marginTop:"3px", marginLeft:"10px"}}>
                  Category Name
              </div>)
        },
        customBodyRender: (row, data) => {
          return (
            <p
              style={{
                width: "100%",
                textAlign: "left",
                margin:"5px 15px 5px 15px",
                // padding: "5px",
                // fontSize:"14px"
              }}
            >
              {row}
            </p>
          );
        },
      },
    },
    {
      name: "ADDED_ON",
      label: "Added On",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return (
            <p
              // style={{
              //   width: "100%",
              //   textAlign: "left",
                // marginRight:"15px",
                // padding: "5px",
                // fontSize:"14px"
              // }}
            >
              {row}
            </p>
          );
        },
      },
    },
    // {
    //   name: "PRIORITY_TITLE",
    //   label: "Priority",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (row, data) => {
    //       return (
    //         <p
    //           style={{
    //             textAlign: "center",
    //             marginBottom: "0px",
    //           }}
    //         >
    //           {row}
    //         </p>
    //       );
    //     },
    //   },
    // },
    
  ];

  const options = {
    filterType: "checkbox",
  };

  const onClickEdit = (data) => {
    setisEdit(true);
    // let updated_cat = JSON.parse(data?.rowData[1]?.replaceAll("[]", ""));
    // setSelectedPriorityId(updated_cat)
    handleOpenAddCategory();
    setCategory(data.rowData[6]);
    setCategoryId(data.rowData[0]);
    // setPriorityId(data.rowData[1]);
  };

  const onClickDelete = (data) => {
    // setIsDelete(true);
    setCategoryId(data.rowData[0]);
    setCategory(data.rowData[6]);
    // setPriorityId(data.rowData[1]);
    handleDeleteCategory(data.rowData[0]);
  };

  return (
    <>
      <MiniDrawer header_name="Category Master" />
      <div className="container-fluid">
        {isLoading ? (
          <Loader />
        ) : (
          <div id="categoryTable">
            <MUIDataTable
              title={
                <h4
                  className="tableName"
                  style={{ textAlign: "left", marginBottom: "0px" }}
                >
                  Category List
                </h4>
              }
              data={categoryList}
              columns={columns}
              options={{
                options: options,
                selectableRows: "none",
                viewColumns: false,
                filter: false,
                print: false,
                responsive: "standard",
                download: false,
                fixedHeader: true,
                tableBodyMaxHeight: "63vh",
                rowsPerPageOptions: [10, 25, 50, 100],
                setTableProps: () => {
                  return {
                    padding: "default",
                  };
                },
                customToolbar: () => {
                  return (
                    <button
                      className="btn btn-outline-primary btn-sm addButton"
                      onClick={handleOpenAddCategory}
                    >
                      Add Category &nbsp;
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  );
                },
                textLabels: {
                  body: {
                    noMatch: (
                      <p style={{ fontSize: "14px", margin: "5px 0px" }}>
                        Data Not Available
                      </p>
                    ),
                  },
                },
              }}
            />
          </div>
        )}
      </div>
      <AddNewCategory
        base_url={base_url}
        open={open}
        setOpen={setOpen}
        handleOpenAddCategory={handleOpenAddCategory}
        handleCloseAddCategory={handleCloseAddCategory}
        category={category}
        setCategory={setCategory}
        setCategoryId={setCategoryId}
        priorityList={priorityList}
        priorityId={priorityId}
        setPriorityId={setPriorityId}
        handleAddCategory={handleAddCategory}
        isEdit={isEdit}
        setisEdit={setisEdit}
        handleEditCategory={handleEditCategory}
        selectedPriorityId={selectedPriorityId}
        setSelectedPriorityId={setSelectedPriorityId}
        msg_popUp={msg_popUp}
      />
    </>
  );
}

export default CategoryPage;
